import { CButton, CModalFooter } from '@coreui/react-pro';
import { BonusFormData } from 'common/types/types';
import { Spinner } from 'components/common/spinner/spinner';
import { useFormContext } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface IModalFooterProps {
  onClose: () => void,
}

const ModalFooter: FC<IModalFooterProps> = ({ onClose }) => {
  const { t } = useTranslation('common');
  const { formState: { isSubmitting } } = useFormContext<BonusFormData>();

  return (
    <CModalFooter className={ styles.modalFooter }>
      <CButton
        color="dark"
        variant = "ghost"
        onClick={ onClose }
      >
        { t('closeBtn') }
      </CButton>
      <CButton
        color="primary"
        type="submit"
        disabled={ isSubmitting }
      >
        { isSubmitting  && (<Spinner color="light"/>) }
        { t('saveBtn') }
      </CButton>
    </CModalFooter>
  );
};

export { ModalFooter };
