import { CButton } from '@coreui/react-pro';
import { EmployeeTableRow } from 'common/types/types';
import { EmployeeModal } from 'components/modals/employee-modal/employee-modal';
import { useAppDispatch , useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { employeesActions } from 'store/actions';

import styles from './style.module.scss';

interface INameCellProps extends EmployeeTableRow {
  carServiceId: number,
}

const NameCell: FC<INameCellProps> = ({ id, fullName, carServiceId }) => {
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isDisableModal, setIsDisableModal] = useState<boolean>(false);

  const onOpenViewModal = (): void => {
    setIsOpenModal(true);
    setIsDisableModal(true);
  };

  const getListEmployees = (): void => {
    dispatch(employeesActions.getEmployees({ carServiceId }));
  };

  return (
    <td>
      <CButton
        color="link"
        size="sm"
        className={styles.nameLink}
        onClick={ onOpenViewModal }
      >
        { fullName }
      </CButton>
      {
        isOpenModal && (
          <EmployeeModal
            isOpen={ true }
            carServiceId={ carServiceId }
            employeeId={ id }
            isDisable={ isDisableModal }
            onEnable={ (): void => setIsDisableModal(false) }
            onClose={ (): void => setIsOpenModal(false) }
            onAfterSubmit={ getListEmployees }
          />
        )
      }
    </td>
  );
};

export { NameCell };
