import { TableColumn } from 'common/types/types';

const getOrderTableColumn = (labels: string): TableColumn[] => {
  const labelsArr = labels.split('|');

  return [
    {
      key: 'id',
      label: labelsArr[0],
      filter: false,
      _style: { width: '70px' },
    },
    {
      key: 'dateCarReceive',
      label: labelsArr[2],
      filter: false,
      _style: { width: '160px' },
    },
    {
      key: 'auto',
      label: labelsArr[1],
      filter: false,
      _style: { width: '200px' },
    },
    {
      key: 'autoColor',
      label: labelsArr[8],
      filter: false,
      sorter: false,
      _style: { width: '40px' },
    },
    {
      key: 'autoNum',
      label: labelsArr[9],
      filter: false,
      sorter: false,
      _style: { width: '125px' },
    },
    {
      key: 'comment',
      label: labelsArr[4],
      filter: false,
      sorter: false,
    },
    {
      key: 'totalSum',
      label: labelsArr[5],
      filter: false,
      _style: { width: '110px' },
    },
    {
      key: 'sumOrders',
      label: labelsArr[6],
      filter: false,
      _style: { width: '110px' },
    },
    {
      key: 'sumWorksWithoutOrders',
      label: labelsArr[7],
      filter: false,
      _style: { width: '110px' },
    },
    {
      key: 'statusName',
      label: labelsArr[3],
      _style: { width: '150px' },
    },
    {
      key: 'show_details',
      label: '',
      filter: false,
      sorter: false,
      _style: { width: '40px' },
    },
  ];
};

export { getOrderTableColumn };
