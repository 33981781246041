import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey, EmployeeFinInfoKey } from 'common/enums/enums';
import { BonusFormData } from 'common/types/types';
import { useFormContext } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const FullnameField: FC = () => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const { register } = useFormContext<BonusFormData>();

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="fullname" className="col-sm-3 col-form-label">
        { `${ t(EmployeeFinInfoKey.EMPLOYEE) }*` }
      </CFormLabel>
      <CCol sm={ 9 }>
        <CFormInput
          { ...register('fullname') }
          id="fullname"
          disabled
        />
      </CCol>
    </CRow>
  );
};

export { FullnameField };
