import { CCol, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey, EmployeeFinInfoKey } from 'common/enums/enums';
import { BonusFormData } from 'common/types/types';
import { TextArea } from 'components/textarea/text-area';
import { useFormContext } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const AddInfoField: FC = () => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const { register, watch } = useFormContext<BonusFormData>();
  const value = watch('addInfo') || '';

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="addInfo" className="col-sm-3 col-form-label">
        { `${ t(EmployeeFinInfoKey.ADD_INFO) }` }
      </CFormLabel>
      <CCol sm={ 9 }>
        <TextArea<BonusFormData>
          id="addInfo"
          register={ register }
          limitSymbols={ 1024 }
          rows={3}
          nameFiled="addInfo"
          value={ value }
        />
      </CCol>
    </CRow>
  );
};

export { AddInfoField };
