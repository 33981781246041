import React, { FC, ReactNode } from 'react';
import { PlacesType, Tooltip } from 'react-tooltip';

type Props = {
  tooltipId: string,
  tooltipContent: string,
  place?: PlacesType | undefined
  children: ReactNode,
  styles?: React.CSSProperties,
};

const AppToolTip: FC<Props> = ({ tooltipId, tooltipContent, children, place = 'left', styles }) => {

  return (
    <>
      <Tooltip
        id={tooltipId}
        place={place}
        opacity={1}
        border="1px solid #d6d8d9"
        style={{ background: 'white', color: '#383838', maxWidth: 400, ...styles }}
      />
      <div data-tooltip-id={tooltipId} data-tooltip-content={tooltipContent}>
        {children}
      </div>
    </>
  );
};

export { AppToolTip };
