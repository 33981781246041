import { CCol, CFormFeedback, CFormLabel, CRow } from '@coreui/react-pro';
import cn from 'classnames';
import { AppTranslationKey, EmployeeFinInfoKey } from 'common/enums/enums';
import { BonusFormData } from 'common/types/types';
import { FILTER_DATE_MONTH_YEAR } from 'constants/date-formats';
import { useFormContext } from 'hooks/hooks';
import React, { FC, ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from 'rsuite/DatePicker';

import styles from './styles.module.scss';

const PeriodField: FC = () => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const { control, formState: { errors } } = useFormContext<BonusFormData>();

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="period" className="col-sm-3 col-form-label">
        { `${ t(EmployeeFinInfoKey.PERIOD) }*` }
      </CFormLabel>
      <CCol sm={ 9 }>
        <Controller
          render={({ field }): ReactElement => (
            <div className={ styles.dataPickerContainer }>
              <DatePicker
                id="period"
                format={FILTER_DATE_MONTH_YEAR}
                oneTap
                placement="bottomStart"
                editable={ false }
                cleanable={ false }
                onChange={ (value): void => field.onChange(value)}
                value={ field.value }
                className={ cn( {
                  'is-invalid': Boolean(errors.period),
                  [styles.errorDateField]: Boolean(errors.period),
                }) }
              />
              <CFormFeedback invalid={ Boolean(errors.period) }>
                {errors.period?.message || ''}
              </CFormFeedback>
            </div>
          )}
          name="period"
          control={ control }
        />
      </CCol>
    </CRow>
  );
};

export { PeriodField };
