import { BreadcrumbItem } from 'common/types/types';
import { useBreadcrumbsContext } from 'contexts';
import { useEffect } from 'hooks/hooks';
import { FC, ReactNode } from 'react';

interface IBreadcrumbsContainerProps {
  breadcrumbs: BreadcrumbItem[],
  children: ReactNode,
}

const BreadcrumbsContainer: FC<IBreadcrumbsContainerProps> = ({ breadcrumbs, children }) => {
  const { setBreadcrumbs } = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs(breadcrumbs);

    return () => setBreadcrumbs([]);
  }, [breadcrumbs]);

  return (
    <>{ children }</>
  );
};

export { BreadcrumbsContainer };
