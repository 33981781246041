import { CButton } from '@coreui/react-pro';
import { AppTranslationKey, EmployeeFinInfoKey } from 'common/enums/enums';
import { BonusModal } from 'components/modals/modals';
import { useState } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IPenaltyButtonProps {
  onLoadEmployee: () => void,
}

const PenaltyButton: FC<IPenaltyButtonProps> = ({ onLoadEmployee }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const onOpenModal = (): void => {
    setIsOpenModal(true);
  };

  const onCloseModal = (): void => {
    setIsOpenModal(false);
  };

  return (
    <>
      <CButton color="danger" variant="outline" onClick={ onOpenModal }>
        { t(EmployeeFinInfoKey.FINE) }
      </CButton>
      <BonusModal
        isOpen={ isOpenModal }
        onClose={ onCloseModal }
        callback={ onLoadEmployee }
        isPenalty
      />
    </>
  );
};

export { PenaltyButton };
