enum EmployeeFinInfoKey {
  TITLE = 'title',
  POSITION = 'position',
  METHOD_CALC_SALARY= 'methodCalcSalary',
  TOTAL_WORK_HOURS = 'totalWorkHours',
  TOTAL_DONE_ORDERS = 'totalDoneOrders',
  TOTAL_DONE_WORKS = 'totalDoneWorks',
  TOTAL_NORM_HOURS = 'totalNormHours',
  SALARY = 'salary',
  SALARY_ACCRUED = 'salaryAccrued',
  BONUS = 'bonus',
  FINES = 'fines',
  TOTAL = 'total',
  PAID_OUT = 'paidOut',
  FOR_PAYMENT = 'forPayment',
  FINE = 'fine',
  PAY_SALARY = 'paySalary',
  FILTERS = 'filters',
  LAST_MONTH = 'lastMonth',
  LAST_WEEK = 'lastWeek',
  TODAY = 'today',
  CURRENT_WEEK = 'currentWeek',
  CURRENT_MONTH = 'currentMonth',
  TOOLTIP_PROFILE_USER = 'tooltipProfileUser',
  BONUS_MODAL_TITLE = 'bonusModalTitle',
  PENALTY_MODAL_TITLE = 'penaltyModalTitle',
  EMPLOYEE = 'employee',
  PERIOD = 'period',
  BONUS_SUM = 'bonusSum',
  PENALTY_SUM = 'penaltySum',
  ADD_INFO = 'addInfo',
  SUCCESS_CREATE_BENEFIT_MESSAGE = 'successCreateBenefitMessage',
  SUCCESS_CREATE_PENALTY_MESSAGE = 'successCreatePenaltyMessage',
}

export { EmployeeFinInfoKey };
