import { BreadcrumbItem } from 'common/types/types';
import React, { createContext, FC, useContext, useState } from 'react';

interface IUseBreadcrumbs {
  breadcrumbs: BreadcrumbItem[],
  setBreadcrumbs: (breadcrumbs: BreadcrumbItem[]) => void,
}

const useBreadcrumbs = (): IUseBreadcrumbs => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  return {
    breadcrumbs,
    setBreadcrumbs,
  };
};

const BreadcrumbsContext = createContext<IUseBreadcrumbs | null>(null);

const useBreadcrumbsContext = (): IUseBreadcrumbs => {
  const context = useContext(BreadcrumbsContext);

  if (!context) {
    throw new Error('useBreadcrumbsContext must be used within the BreadcrumbsContext');
  }

  return context;
};

interface IProps {
  children: React.ReactNode,
}

const BreadcrumbsContextProvider: FC<IProps> = ({ children }) => (
  <BreadcrumbsContext.Provider value={ useBreadcrumbs() }>
    { children }
  </BreadcrumbsContext.Provider>
);

export { BreadcrumbsContextProvider, useBreadcrumbsContext };
