import { AppRoute, EmployeesRoute, HelpRoute, PersonalRoute, ServicesRoute } from 'common/enums/enums';
import { BreadcrumbItem } from 'common/types/types';

const DASHBOARD: BreadcrumbItem[] = [
  { keyNameTranslate: 'dashboard', isActive: true },
];

const PROFILE: BreadcrumbItem[] = [
  { keyNameTranslate: 'profile', isActive: true },
];

const CAR_SERVICES: BreadcrumbItem[] = [
  {
    keyNameTranslate: 'myAutoServices',
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.CAR_SERVICES_PROFILES}`,
  },
];

const CAR_SERVICES_PROFILES: BreadcrumbItem[] = [
  ...CAR_SERVICES,
  { keyNameTranslate: 'carServicesProfiles', isActive: true },
];

const CAR_SERVICES_PROFILES_ADD: BreadcrumbItem[] = [
  ...CAR_SERVICES,
  {
    keyNameTranslate: 'carServicesProfiles',
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.CAR_SERVICES_PROFILES}`,
  },
  { keyNameTranslate: 'add', isActive: true },
];

const CAR_SERVICES_AUTO: BreadcrumbItem[] = [
  ...CAR_SERVICES,
  { keyNameTranslate: 'carServicesAuto', isActive: true },
];

const CAR_SERVICES_ORDERS: BreadcrumbItem[] = [
  ...CAR_SERVICES,
  { keyNameTranslate: 'carServicesOrders', isActive: true },
];

const CAR_SERVICES_WORKS: BreadcrumbItem[] = [
  ...CAR_SERVICES,
  { keyNameTranslate: 'work', isActive: true },
];

const CAR_SERVICES_WORK_ORDERS: BreadcrumbItem[] = [
  ...CAR_SERVICES,
  { keyNameTranslate: 'workOrders', isActive: true },
];

const CUSTOMERS: BreadcrumbItem[] = [
  { keyNameTranslate: 'carServicesCustomers', isActive: true },
];

const STORAGE: BreadcrumbItem[] = [
  {
    keyNameTranslate: 'storage',
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.STORAGE_PROFILES}`,
  },
];

const STORAGE_PROFILES: BreadcrumbItem[] = [
  ...STORAGE,
  { keyNameTranslate: 'storagesProfiles', isActive: true },
];

const STORAGE_PROFILES_ADD: BreadcrumbItem[] = [
  ...STORAGE,
  {
    keyNameTranslate: 'storagesProfiles',
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.STORAGE_PROFILES}`,
  },
  { keyNameTranslate: 'add', isActive: true },
];

const STORAGE_PROFILES_EDIT: BreadcrumbItem[] = [
  ...STORAGE,
  {
    keyNameTranslate: 'storagesProfiles',
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.STORAGE_PROFILES}`,
  },
  { keyNameTranslate: 'edit', isActive: true },
];

const SUPPLIERS: BreadcrumbItem[] = [
  ...STORAGE,
  { keyNameTranslate: 'suppliers', isActive: true },
];

const SUPPLIERS_ADD: BreadcrumbItem[] = [
  ...STORAGE,
  {
    keyNameTranslate: 'suppliers',
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.SUPPLIERS}`,
  },
  { keyNameTranslate: 'add', isActive: true },
];

const SUPPLIERS_EDIT: BreadcrumbItem[] = [
  ...STORAGE,
  {
    keyNameTranslate: 'suppliers',
    path: `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.SUPPLIERS}`,
  },
  { keyNameTranslate: 'edit', isActive: true },
];

const SPARE_PARTS: BreadcrumbItem[] = [
  ...STORAGE,
  { keyNameTranslate: 'spareParts', isActive: true },
];

const CASH_DESK: BreadcrumbItem[] = [
  { keyNameTranslate: 'cashDesk', isActive: true },
];

const HELP: BreadcrumbItem[] = [
  {
    keyNameTranslate: 'help',
    path: `${AppRoute.HELP}${HelpRoute.ROOT}${HelpRoute.VIDEO_REVIEW}`,
  },
];

const VIDEO_REVIEW: BreadcrumbItem[] = [
  ...HELP,
  { keyNameTranslate: 'videoReview', isActive: true },
];

const TARIFFS: BreadcrumbItem[] = [
  ...HELP,
  { keyNameTranslate: 'tariffs', isActive: true },
];

const ABOUT_REQUEST: BreadcrumbItem[] = [
  ...HELP,
  { keyNameTranslate: 'allAboutRequest', isActive: true },
];

const SERVICE: BreadcrumbItem[] = [
  {
    keyNameTranslate: 'services',
    path: `${AppRoute.SERVICES}${ServicesRoute.ROOT}${ServicesRoute.INFO_VIN}`,
  },
];

const INFO_VIN: BreadcrumbItem[] = [
  ...SERVICE,
  { keyNameTranslate: 'infoVin', isActive: true },
];

const EMPLOYESS: BreadcrumbItem[] = [
  {
    keyNameTranslate: 'employees',
    path: `${AppRoute.EMPLOYEES}${EmployeesRoute.ROOT}${EmployeesRoute.PROFILES}`,
  },
];

const EMPLOYEES_PROFILES: BreadcrumbItem[] = [
  ...EMPLOYESS,
  { keyNameTranslate: 'employeesProfiles', isActive: true },
];

const SALARY: BreadcrumbItem[] = [
  ...EMPLOYESS,
  { keyNameTranslate: 'salary', isActive: true },
];

const FIN_INFO: BreadcrumbItem[] = [
  ...EMPLOYESS,
  {
    keyNameTranslate: 'salary',
    path: `${AppRoute.EMPLOYEES}${EmployeesRoute.ROOT}${EmployeesRoute.SALARY}`,
  },
  { keyNameTranslate: 'finInfo', isActive: true },
];

export {
  ABOUT_REQUEST,
  CAR_SERVICES_AUTO,
  CAR_SERVICES_ORDERS,
  CAR_SERVICES_PROFILES,
  CAR_SERVICES_PROFILES_ADD,
  CAR_SERVICES_WORK_ORDERS,
  CAR_SERVICES_WORKS,
  CASH_DESK,
  CUSTOMERS,
  DASHBOARD,
  EMPLOYEES_PROFILES,
  FIN_INFO,
  INFO_VIN,
  PROFILE,
  SALARY,
  SPARE_PARTS,
  STORAGE_PROFILES,
  STORAGE_PROFILES_ADD,
  STORAGE_PROFILES_EDIT,
  SUPPLIERS,
  SUPPLIERS_ADD,
  SUPPLIERS_EDIT,
  TARIFFS,
  VIDEO_REVIEW,
};
