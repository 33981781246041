import { BonusFormData, EmployeeItem } from 'common/types/types';
import { getFullName } from 'helpers/helpers';

const getDefaultBonusFormPayload = (employee?: EmployeeItem): BonusFormData => ({
  id: employee?.employeeId || -1,
  fullname: getFullName(
    employee?.firstName || '',
    employee?.patronymicName || '',
    employee?.secondName || '',
  ),
  period: new Date(),
  bonusValue: null,
  addInfo: '',
});

export { getDefaultBonusFormPayload };
