import { cilCash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react-pro';
import { AppTranslationKey, EmployeeFinInfoKey, NotificationType } from 'common/enums/enums';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { appActions } from 'store/actions';

import { BonusButton } from './buttons/bonus-button';
import { PenaltyButton } from './buttons/penalty-button';
import styles from './styles.module.scss';

interface ISalaryWidgetProps {
  onLoadEmployee: () => void,
}

const SalaryWidget: FC<ISalaryWidgetProps> = ({ onLoadEmployee }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const { t: tCommon } = useTranslation(AppTranslationKey.COMMON);
  const employee = useAppSelector(({ employees }) => employees.currentEmployee);
  const dispatch = useAppDispatch();

  const handlePaySalaryClick = (): void => {
    dispatch(
      appActions.notify({
        type: NotificationType.INFO,
        message: t('comingSoon', { ns: AppTranslationKey.COMMON }),
      }),
    );
  };

  return (
    <div className={ styles.salaryWidget }>
      <div className={ styles.title }>
        { t(EmployeeFinInfoKey.SALARY) }
      </div>
      <div className={ styles.infoSalary }>
        <CIcon icon={ cilCash } className={ styles.icon } />
        <div className={ styles.infoSalaryValue }>
          <span>{ `${ t(EmployeeFinInfoKey.SALARY_ACCRUED) }:` }</span>
          <span>{ `${ employee?.sumSalaryWithoutBenefit || 0 } ${ tCommon('uah') }` }</span>
        </div>
      </div>
      <div className={ styles.bonusesBlock }>
        <div className={ styles.bonusItem }>
          <span className={ styles.bonusLabel }>
            { `${ t(EmployeeFinInfoKey.BONUS) }:`}
          </span>
          <span className={ styles.bonusValue }>
            { `${ employee?.sumBonus || 0 } ${ tCommon('uah') }` }
          </span>
        </div>
        <div className={ styles.bonusItem }>
          <span className={ styles.fineTitle }>
            { `${ t(EmployeeFinInfoKey.FINES) }:`}
          </span>
          <span className={ styles.fineValue }>
            { `${ employee?.sumPenalty || 0 } ${ tCommon('uah') }` }
          </span>
        </div>
        <div className={ styles.bonusItem }>
          <span>
            { `${ t(EmployeeFinInfoKey.TOTAL) }:`}
          </span>
          <span className={ styles.totalValue }>
            { `${ employee?.sumSalary || 0 } ${ tCommon('uah') }` }
          </span>
        </div>
      </div>
      <div className={ styles.paymentBLock }>
        <div className={ styles.paymentItem }>
          <span>
            { `${ t(EmployeeFinInfoKey.PAID_OUT) }:`}
          </span>
          <span className={ styles.paidValue }>
            { `${ employee?.sumPaid || 0 } ${ tCommon('uah') }` }
          </span>
        </div>
        <div className={ styles.paymentItem }>
          <span>
            { `${ t(EmployeeFinInfoKey.FOR_PAYMENT) }:`}
          </span>
          <span className={ styles.payValue }>
            { `${ employee?.sumNoPaid || 0 } ${ tCommon('uah') }` }
          </span>
        </div>
      </div>
      <div className={ styles.buttonsContainer }>
        <PenaltyButton onLoadEmployee={ onLoadEmployee }/>
        <BonusButton onLoadEmployee={ onLoadEmployee } />
        <CButton color="primary" onClick={handlePaySalaryClick}>
          { t(EmployeeFinInfoKey.PAY_SALARY) }
        </CButton>
      </div>
    </div>
  );
};

export { SalaryWidget };
