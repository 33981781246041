import { CButton } from '@coreui/react-pro';
import { AppRoute , AppTranslationKey , EmployeesRoute,SalaryKey  } from 'common/enums/enums';
import { SalaryTableRow } from 'common/types/types';
import { AppToolTip } from 'components/common/common';
import { useNavigate } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface INameCellProps extends SalaryTableRow {
  carServiceId: number,
}

const NameCell: FC<INameCellProps> = ({ id, fullName, carServiceId }) => {
  const { t } = useTranslation(AppTranslationKey.SALARY);
  const navigate = useNavigate();
  const idTooltip = `fullNameCell_${ id }`;
  const contentTooltip = t(`${SalaryKey.COLUMNS}.${SalaryKey.FIN_INFO_CELL_TOOLTIP}`, { id });

  const openFinInfoPage = (): void => {
    navigate(`${ AppRoute.EMPLOYEES }/${ EmployeesRoute.FIN_INFO }/${ id }?carServiceId=${ carServiceId }`);
  };

  return (
    <td>
      <AppToolTip
        tooltipId={idTooltip}
        tooltipContent={contentTooltip}
        place={'bottom'}
        styles={{  background: '#4c4c4c', color: 'white', opacity: 0.3 }}
      >
        <CButton
          color="link"
          size="sm"
          className={styles.nameLink}
          onClick={ openFinInfoPage }
        >
          { fullName }
        </CButton>
      </AppToolTip>
    </td>
  );
};

export { NameCell };
