import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppTranslationKey, BenefitType, EmployeeFinInfoKey } from 'common/enums/enums';
import { AsyncThunkConfig, Benefit, BenefitRequestPayload } from 'common/types/types';
import { t } from 'i18next';

import { ActionType } from './common';

const createBenefit = createAsyncThunk<
  Benefit,
  BenefitRequestPayload,
  AsyncThunkConfig
>(ActionType.CREATE_BENEFIT, async (payload, { extra }) => {
  const { benefitApi, notification } = extra;
  const benefit = await benefitApi.createBenefit(payload);
  const successMessage = payload.employeeBenefitTypeId === BenefitType.BENEFIT
    ? t(`${AppTranslationKey.EMPLOYEE_FIN_INFO}:${EmployeeFinInfoKey.SUCCESS_CREATE_BENEFIT_MESSAGE}`)
    : t(`${AppTranslationKey.EMPLOYEE_FIN_INFO}:${EmployeeFinInfoKey.SUCCESS_CREATE_PENALTY_MESSAGE}`);

  notification.success(successMessage);

  return benefit;
});

export { createBenefit };
