import { CSmartTable } from '@coreui/react-pro';
import { SorterValue } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey, DataStatus } from 'common/enums/enums';
import { SalaryTableRow } from 'common/types/types';
import { NoData } from 'components/common/no-data/no-data';
import { FILTER_DATE_SHORT_MONTH_YEAR } from 'constants/date-formats';
import { useFilterContext } from 'contexts';
import { format, lastDayOfMonth, parse } from 'date-fns';
import { useAppDispatch, useAppSelector, useEffect, useMemo } from 'hooks/hooks';
import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { employeesActions } from 'store/actions';

import { getTableColumn, getTableData } from './helpers';
import { NameCell } from './name-cell';
import styles from './styles.module.scss';

interface ISalaryTableProps {
  activeCarServiceId: number,
}

const SalariesTable: FC<ISalaryTableProps> = ({ activeCarServiceId }) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(AppTranslationKey.COMMON);
  const [sorterValue, setSorterValue] = useState<SorterValue>({ column: 'id', state: 'desc' });
  const {
    employees: { data: employees },
    dataStatusEmployees,
  } = useAppSelector(({ employees }) => employees);
  const { getValue, isInit } = useFilterContext();
  const period = getValue('period') as string;
  const { salaryDateFrom, salaryDateTo } = useMemo(() => {
    const value = parse(period, FILTER_DATE_SHORT_MONTH_YEAR, new Date());
    const salaryDateFrom = format(value, 'yyyyMM01');
    const salaryDateTo = format(lastDayOfMonth(value), 'yyyyMMdd');

    return { salaryDateFrom, salaryDateTo };
  }, [period]);
  const items = useMemo(() => getTableData(employees, sorterValue), [employees, sorterValue]);

  useEffect(() => {
    if (activeCarServiceId && isInit) {
      dispatch(employeesActions.getEmployees({
        carServiceId: activeCarServiceId,
        salaryDateFrom,
        salaryDateTo,
      }));
    }
  }, [activeCarServiceId, i18n.language, salaryDateFrom, salaryDateTo, isInit]);

  return (
    <CSmartTable
      columns={ getTableColumn() }
      items={ items }
      columnSorter={{ external: true, resetable: false }}
      onSorterChange={ setSorterValue }
      sorterValue={{ column: 'id', state: 'desc' }}
      tableHeadProps={{ color: 'light' }}
      tableProps={{ striped: false, hover: true, align: 'middle' }}
      pagination={ false }
      itemsPerPage={ items.length }
      noItemsLabel={
        <NoData
          title={t('noItemsFoundTitle')}
          message={t('noItemsFoundMsg')}
        />
      }
      loading={ dataStatusEmployees === DataStatus.PENDING }
      scopedColumns={ {
        rate: (item: SalaryTableRow): ReactElement => <td>{ `${ item.rate } ${ t('uah') }` }</td>,
        hourlyRate: (item: SalaryTableRow): ReactElement => <td>{ `${ item.hourlyRate } ${ t('uah') }` }</td>,
        percentWorks: (item: SalaryTableRow): ReactElement => <td>{ `${ item.percentWorks } ${ t('uah') }` }</td>,
        percentSpares: (item: SalaryTableRow): ReactElement => <td>{ `${ item.percentSpares } ${ t('uah') }` }</td>,
        bonus: (item: SalaryTableRow): ReactElement => <td>{ `${ item.bonus } ${ t('uah') }` }</td>,
        penalty: (item: SalaryTableRow): ReactElement => <td>{ `${ item.penalty } ${ t('uah') }` }</td>,
        salaryAll: (item: SalaryTableRow): ReactElement => (
          <td className={ styles.allSalaryCell }>{ `${ item.salaryAll } ${ t('uah') }` }</td>
        ),
        fullName: (item: SalaryTableRow): ReactElement => <NameCell { ...item } carServiceId={ activeCarServiceId } />,
      } }
    />
  );
};

export { SalariesTable };
