import { TableColumn } from 'common/types/types';

const getColumns = (labels: string): TableColumn[] => {
  const labelsArr = labels.split('|');

  return [
    {
      key: 'name',
      label: labelsArr[1],
      filter: true,
      _style: { width: '25%' },
    },
    {
      key: 'status',
      label: labelsArr[2],
      filter: true,
      _style: { width: '15%' },
    },
    {
      key: 'address',
      label: labelsArr[3],
      filter: true,
    },
    {
      key: 'showDetails',
      label: '',
      _style: { width: '1%' },
      filter: false,
      sorter: false,
    },
  ];
};

export { getColumns };
