import { configureStore } from '@reduxjs/toolkit';
import {
  authApi,
  benefitApi,
  carApi,
  carServicesApi,
  carWorkApi,
  cashDeskApi,
  confidentialTextApi,
  dictionaryApi,
  documentationApi,
  employeesApi,
  errorHandlerApi,
  feedBackApi,
  notification,
  orderApi,
  sparePartsApi,
  stockApi,
  storage,
  supplierApi,
  tariffsApi,
  userApi,
  widgetApi,
  workOrderApi,
} from 'services/services';

import { handleError } from './middlewares/middlewares';
import { rootReducer } from './root-reducer';

const extraArgument = {
  authApi,
  cashDeskApi,
  dictionaryApi,
  employeesApi,
  errorHandlerApi,
  storage,
  stockApi,
  notification,
  orderApi,
  userApi,
  carServicesApi,
  carApi,
  widgetApi,
  supplierApi,
  carWorkApi,
  sparePartsApi,
  feedBackApi,
  tariffsApi,
  workOrderApi,
  documentationApi,
  confidentialTextApi,
  benefitApi,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: { extraArgument },
    }).concat([ handleError ]);
  },
});

export { extraArgument, store };
