import { AppTranslationKey, WorkOrderTranslationKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t } from 'i18next';

const getWorkOrderTableColumns = (): TableColumn[] => {
  return [
    {
      key: 'orderId',
      label: '#',
      _style: { width: '70px' },
    },
    {
      key: 'requestId',
      label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.NUM_ORDER}`),
      _style: { width: '90px' },
    },
    {
      key: 'dateTime',
      label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.DATE_TIME}`),
      filter: false,
      _style: { width: '160px' },
    },
    {
      key: 'carName',
      label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.AUTO}`),
      filter: false,
      _style: { width: '200px' },
    },
    {
      key: 'carColor',
      label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.CAR_COLOR}`),
      filter: false,
      sorter: false,
      _style: { width: '40px' },
    },
    {
      key: 'carNum',
      label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.CAR_NUM}`),
      filter: false,
      sorter: false,
      _style: { width: '125px' },
    },
    {
      key: 'comment',
      label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.COMMENT}`),
      filter: false,
      sorter: false,
    },
    {
      key: 'sumFull',
      label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.SUM_FULL}`),
      filter: false,
      _style: { width: '110px' },
    },
    {
      key: 'sumWorks',
      label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.SUM_WORKS}`),
      filter: false,
      _style: { width: '110px' },
    },
    {
      key: 'sumSpareParts',
      label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.SUM_SPARE_PARTS}`),
      filter: false,
      _style: { width: '110px' },
    },
    {
      key: 'status',
      label: t(`${AppTranslationKey.WORK_ORDER}:${WorkOrderTranslationKey.STATUS}`),
      filter: false,
      _style: { width: '150px' },
    },
    {
      key: 'show_details',
      label: '',
      _style: { width: '40px' },
      filter: false,
      sorter: false,
    },
  ];
};

export { getWorkOrderTableColumns };
