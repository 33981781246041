import { TableColumn } from 'common/types/types';

const getCarsTableColumn = (labels: string): TableColumn[] => {
  const labelsArr = labels.split('|');

  return [
    {
      key: 'carRegNum',
      label: labelsArr[1],
      filter: false,
      sorter: false,
      _style: { width: '20%' },
    },
    {
      key: 'carBrandName',
      label: labelsArr[3],
      _style: { width: '15%' },
      filter: false,
    },
    {
      key: 'carModelName',
      label: labelsArr[2],
      _style: { width: '15%' },
      filter: false,
    },
    {
      key: 'carColorName',
      label: labelsArr[4],
      _style: { width: '10%' },
      filter: false,
      sorter: false,
    },
    {
      key: 'vinCode',
      label: labelsArr[5],
      filter: false,
      sorter: false,
    },
    {
      key: 'show_details',
      label: '',
      _style: { width: '1%' },
      filter: false,
      sorter: false,
    },
  ];
};

export { getCarsTableColumn };
