enum WorkOrderTranslationKey {
  ADD_WORK_ORDER = 'addWorkOrder',
  NO_WORK_ORDERS_TITLE = 'noWorkOrdersTitle',
  NO_WORK_ORDERS_TEXT = 'noWorkOrdersText',
  WORK_ORDER_MODAL = 'workOrderModal',
  NUM_ORDER = 'numOrder',
  DATE_TIME = 'dateTime',
  STATUS = 'status',
  AUTO = 'auto',
  ADD_SUCCESS = 'addSuccess',
  WORK_ORDER_DETAILS_WORK_TITLE = 'work-order-details-work-title',
  WORK_ORDER_DETAILS_SPARE_PARTS_TITLE = 'work-order-details-spare-parts-title',
  WORK_ORDER_NO_WORK = 'work-order-no-work',
  WORK_ORDER_NO_SPARE_PARTS = 'work-order-no-spare-parts',
  TOTAL_WORK_ORDER_SUM = 'total-work-order-sum',
  TITLE = 'title',
  SUM_FULL = 'sumFull',
  SUM_WORKS = 'sumWorks',
  SUM_SPARE_PARTS = 'sumSpareParts',
  COMMENT = 'comment',
  EDIT_SUCCESS = 'editSuccess',
  CREATE_CANCELED_WORK_ORDER_ERROR_TEXT = 'createCanceledWorkOrderErrorText',
  EDIT_CANCELED_WORK_ORDER_ERROR_TEXT = 'editCanceledWorkOrderErrorText',
  SELECTED_ORDER_MSG = 'selectedOrderMsg',
  CAR_COLOR = 'carColor',
  CAR_NUM = 'carNumber',
}

export { WorkOrderTranslationKey };
