import cn from 'classnames';
import { useFilterContext } from 'contexts';
import { format } from 'date-fns';
import { getPeriodCurrentMonth } from 'helpers/helpers';
import { useAppDispatch, useCallback, useEffect, useMemo } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { employeesActions } from 'store/actions';

import { CommonInfoWidget } from './common-info-widget';
import { NormaHoursWidget } from './norma-hours-widget';
import { OrdersWidget } from './orders-widget';
import { SalaryWidget } from './salary-widget';
import styles from './styles.module.scss';
import { WorksHoursWidget } from './works-hours-widget';
import { WorksWidget } from './works-widget';

interface IWidgetsProps {
  employeeId: number,
  carServiceId: number,
}

const Widgets: FC<IWidgetsProps> = ({ employeeId, carServiceId }) => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const { getValue, isInit } = useFilterContext();
  const { dateFrom, dateTo } = (
    getValue('rangeDate') || getPeriodCurrentMonth()
  ) as { dateFrom: string, dateTo: string };
  const { salaryDateFrom, salaryDateTo } = useMemo(() => {
    const salaryDateFrom = format(new Date(dateFrom), 'yyyyMMdd');
    const salaryDateTo = format(new Date(dateTo), 'yyyyMMdd');

    return { salaryDateFrom, salaryDateTo };
  }, [dateFrom, dateTo]);

  const loadEmployeeData = useCallback(() => {
    dispatch(employeesActions.getEmployee({ employeeId, carServiceId, salaryDateFrom, salaryDateTo }));
  }, [employeeId, carServiceId, salaryDateFrom, salaryDateTo]);

  useEffect(() => {
    if (isInit) {
      loadEmployeeData();
    }
  }, [employeeId, carServiceId, i18n.language, isInit]);

  return (
    <div className={ styles.widgetsContainer }>
      <div className={ cn( styles.widgetItem, styles.commonIfo ) }>
        <CommonInfoWidget
          carServiceId={ carServiceId }
          salaryDateFrom={ salaryDateFrom }
          salaryDateTo={ salaryDateTo }
        />
      </div>
      <div className={ cn( styles.widgetItem, styles.workHour ) }>
        <WorksHoursWidget />
      </div>
      <div className={ cn( styles.widgetItem, styles.ordersDone ) }>
        <OrdersWidget />
      </div>
      <div className={ cn( styles.widgetItem, styles.salary ) }>
        <SalaryWidget onLoadEmployee={ loadEmployeeData } />
      </div>
      <div className={ cn( styles.widgetItem, styles.worksDone ) }>
        <WorksWidget />
      </div>
      <div className={ cn( styles.widgetItem, styles.normHour ) }>
        <NormaHoursWidget />
      </div>
    </div>
  );
};

export { Widgets };
