import { CButton, CCol, CForm, CListGroup, CRow } from '@coreui/react-pro';
import { DataStatus } from 'common/enums/enums';
import { CarServiceDto, CarServiceProfileFormData, CarServiceRequest } from 'common/types/types';
import { PassLogo } from 'components/car-services-profiles/common/type/type';
import { Spinner, usePermissionToastContext } from 'components/common/common';
import { useAppDispatch, useAppSelector, useEffect, useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { carServicesActions } from 'store/actions';

import { ContactCard, DetailsCard, InfoCard, WebCard } from '../common/common';
import { DEFAULT_EDIT_CAR_SERVICE_PAYLOAD } from '../common/def-payload';
import { CONTACT_TYPE } from './contact-type.enum';
import { getListContactsFromFormData } from './get-list-contacts-from-form-data';
import { getListRequisitesFromFormData } from './get-list-requisites-from-form-data';

type Props = {
  carService: CarServiceDto | null;
};

const CarServiceDetails: FC<Props> = ({ carService }) => {
  const { t } = useTranslation('carServiceDetails');
  const { onPermissionAction } = usePermissionToastContext();
  const dispatch = useAppDispatch();
  const [disable, setDisable] = useState(true);

  const { dataStatus } = useAppSelector(({ carServices }) => ({
    dataStatus: carServices.updateDataStatus,
  }));

  const isLoading = dataStatus === DataStatus.PENDING;

  const {
    register,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CarServiceProfileFormData>({
    defaultValues: DEFAULT_EDIT_CAR_SERVICE_PAYLOAD,
  });

  const watchInfo = watch('info');
  const logo = watch('profilePhoto');

  useEffect(() => {
    if (carService) {
      reset({
        name: carService.name,
        address: carService.address,
        info: carService.info,
        id: carService.id,
        statusId: carService.statusId,
        requisites: carService.requisites?.find(
          (it) => it.flMain) ?? null,
        mainContactPhone: carService.contacts?.find(
          (it) => it.contactTypeId === CONTACT_TYPE.PHONE && it.flMain) ?? null,
        contactPhone: carService.contacts?.find(
          (it) => it.contactTypeId === CONTACT_TYPE.PHONE && !it.flMain) ?? null,
        contactEmail: carService.contacts?.find((it) => it.contactTypeId === CONTACT_TYPE.E_MAIL) ?? null,
        contactWeb: carService.contacts?.find((it) => it.contactTypeId === CONTACT_TYPE.WEB) ?? null,
        contactTelegram: carService.contacts?.find((it) => it.contactTypeId === CONTACT_TYPE.TELEGRAM) ?? null,
        contactViber: carService.contacts?.find((it) => it.contactTypeId === CONTACT_TYPE.VIBER) ?? null,
        profilePhoto: carService.profilePhoto,
      });
    }
  }, [ reset, carService ]);

  const handleSetLogo = (logo: PassLogo): void => {
    setValue('profilePhoto', logo.base64);
    setValue('profileLogoName', logo.name);
  };

  const enableEditCarService = (): void => {
    setDisable(false);
  };

  const onSubmit = (data: CarServiceProfileFormData ): void => {

    const payload: CarServiceRequest = {
      serviceId: data.id,
      address: data.address,
      contacts: getListContactsFromFormData(data),
      info: data.info,
      name: data.name,
      profilePhotoBase64: data.profilePhoto?.split(',')[1],
      profilePhotoName: data.profileLogoName,
      requisites: getListRequisitesFromFormData(data),
      statusId: data.statusId,
    };

    dispatch(carServicesActions.updateCarServiceById(payload)).unwrap().then(() => setDisable(true));
  };

  if(isLoading) {
    return <Spinner isOverflow containerHeight={250} />;
  }

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      {/*todo CListGroup not needed here (02.11.2024)*/}
      <CListGroup flush>
        <CRow>
          <CCol xl={6} md={12}>
            <InfoCard
              errors={errors}
              disabled={disable}
              register={register}
              value={watchInfo}
              photoUrl={logo}
              setLogo={handleSetLogo}
            />
            <WebCard />
          </CCol>
          <CCol xl={6} md={12}>
            <ContactCard register={register} disabled={disable} setValue={setValue}/>
            <DetailsCard disabled={disable} register={register} />
          </CCol>
        </CRow>
        <CCol className="align-self-end pt-3">
          <CButton
            style={{ display: disable ? 'none' : '' }}
            type="submit"
            color="danger"
          >
            {t('carServiceDetailsSaveCaptionButton')}
          </CButton>

          <CButton
            style={{ display: disable ?  '' : 'none' }}
            variant="outline"
            onClick={ onPermissionAction(enableEditCarService) }
          >
            {t('carServiceDetailsEditCaptionButton')}
          </CButton>
        </CCol>
      </CListGroup>
    </CForm>
  );
};

export { CarServiceDetails };
